.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  font-family: "Roboto", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.img-logo {
  width: 52px !important;
  height: 52px !important;
  object-fit: contain;
}

.header-main {
  background-color: #001838 !important;
  height: 70px;
}

.header-main .MuiTypography-root {
  height: 68px;
}

.sub-header .MuiToolbar-root {
  height: 48px !important;
  min-height: 48px !important;
}

.header-main .MuiButtonBase-root {
  padding: 16px;
  text-transform: capitalize !important;
  font-weight: 700 !important;
}

.more-icon {
  height: 20px;
  transform: rotate(90deg);
}

.d-flex {
  display: flex;
  
}

.align-end {
  justify-content: end !important;
}

.h-68 {
  height: 68px;
}

.h-48 {
  height: 48px !important;
}

.MuiToolbar-gutters {
  max-width: 1350px;
  width: 1350px;
  margin: 0 auto;
}

.MuiGrid-container {
  width: 1350px !important;
  max-width: 1350px !important;
  margin: 0 auto !important;
}


.sub-header {
  background-color: #c3c5c8 !important;
  box-shadow: none !important;
  height: 48px;
}

.sub-header .MuiButtonBase-root {
  font-size: 14px !important;
  color: #000;
  font-weight: 700 !important;
  padding: 0 16px;
  text-transform: capitalize !important;
  font-family: "Roboto", sans-serif !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.footer-main {
  background-color: #001838;
  padding: 15px 0;
  position: relative;

}

.footer-main::after {
  position: absolute;
  top: 0;
  content: '';
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(assets/images/45Lines-600x600.png);
}

.footer-right-arrow {
  width: 150px;
  height: 100%;
  position: relative;
  z-index: 9999;
}

.teams-accordion-main {
  background-color: #001838;
  padding: 60px;
  border-radius: 15px;
  margin: 50px 0;
}

.title-main {
  font-size: 35px;
  color: #fff;
}

.mb-20 {
  margin-bottom: 20px;
}

.text-left {
  text-align: left;
}

.accordion-main p {
  text-align: start;
  font-size: 18px;
  font-weight: 600 !important;
}
.accordion-main .MuiAccordionSummary-root{
  padding-left: 25px;
}
.accordion-main .MuiAccordionSummary-content{
padding: 20px 0;
margin: 0 !important;
}
.accordion-main .MuiAccordion-gutters{
  margin-bottom: 15px !important;
  border-radius: 0 !important;
}
.accordion-main .MuiAccordionDetails-root{
padding-left: 25px !important;
padding-bottom: 20px;
}
.acco-title{
  text-align: start !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.acco-sub-title{
  text-align: start !important;
  font-size: 18px;
  font-weight: 400 !important;
}
.accordion-main .share-details-acc{
display: flex;
justify-content: start;
text-align: start;
align-items: center;
}
.accordion-main .img-share{
  height: 35px;
  width: 35px;
  margin-right: 15px;
}

.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.footer-menu{
  background-image: url(assets/images/tsg-pattern-footer.svg);
  height: 600px;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-graphics{
  width: 100%;
  height: 236px !important;
  object-fit: cover;
  border-radius: 15px;
}
.blogs{
  padding: 100px 0 !important;
}
.blog-card{
box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px !important;
border-radius: 15px !important;
}
.blog-content-main{
 padding: 20px;
 height: 170px !important;
}
.blog-title a{
  color: #000;

  font-size: 28px !important;
  font-weight: 600 !important;
  text-align: start !important;
  width: 100% !important;
  display: flex;
}
a:hover{
  text-decoration: underline !important;

  
}
.blog-pera{
  font-size: 16px;
  font-weight: 400 !important;
  text-align: start !important;
  margin-top: 10px;
}
.blog-date{
  font-size: 14px !important;
   color:#919eab !important ;    
   text-align: start !important;
   margin-bottom: 10px !important;
}
.web-title{
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: start !important;
  position: relative;
}
.web-title::after{
  content: '';
  position: absolute;
  top: 20px;
  border: 2px solid #F3F4F6;
  width: 77%;
  right: 0;
}
.line{
  width: 100%;
  height: 1px;
  background-color: #182b530d !important;
}
.accor-tit{
  min-width: 250px !important;
  font-weight: 600 !important;
}
.accor-date{
  text-align: start;
  line-height: 30px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400 !important;
}
.wc-150{
  width: 100px;
}
